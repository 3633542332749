/**
 * Controller registry.
 * @param {String} [selector="[data-do]"]
 * @param {String} [dataProperty="do"]
 * @return {{
 *  apply: (function(*): FlatArray<(Function|undefined)[][], 1>[]),
 *  unapply: function(Array<Function>),
 *  register: function(String, Function),
 * }}
 */
function ControllerRegistry(selector = "[data-do]", dataProperty = "do") {
  const controllers = {};

  /**
   * Register a controller
   * @param {String} name
   * @param {Function} controller
   */
  function register(name, controller) {
    controllers[name] = controller;
  }

  /**
   * Apply controllers to the given container
   * @param {HTMLElement} container
   * @return {(Function|undefined)[]}
   */
  function applyControllers(container) {
    return container.dataset[dataProperty]
      .split(" ")
      .map((name) => {
        if (!name) {
          return;
        }
        const controller = controllers[name];
        if (controller) {
          return controller(container);
        }
        console.warn(`No controller named '${name}' registered!`, container);
      })
      .filter((v) => !!v);
  }

  /**
   * Apply registered controllers in the given container
   * @param {HTMLElement} container
   * @return {FlatArray<(function|undefined)[][], 1>[]}
   */
  function apply(container) {
    return [...container.querySelectorAll(selector)].map(applyControllers).flat();
  }

  /**
   * Unapply any applied controllers by calling each callback.
   * @param {Array<Function>} applied Array of applied controllers
   */
  function unapply(applied) {
    applied.forEach((cb) => cb && cb());
  }

  return {apply, unapply, register};
}

export const Controllers = ControllerRegistry();
