import {Controllers} from "./utils/controllers";

const defaultClassNames = {
  container: "modal",
  content: "site-container modal__content",
  closeButton: "modal__close-button overlay-close",
  showing: "modal--showing",
  closing: "modal--closing",
  centered: "modal--centered",
  scrollLock: "scroll-locked",
};
const animationSpeed = 500;

let stack = 0;

/**
 * Modal instance
 * @typedef ModalInstance
 * @type Object
 * @property HTMLDivElement container
 * @property String className
 * @property Function isOpen
 * @property Function update
 * @property Function callback
 * @property Function close
 * @property HTMLDivElement content
 */

/**
 * Modal dialog
 * @param {String} content
 * @param {Function} onInit
 * @param {Function} onShow
 * @param {Function} onUpdate
 * @param {Function} onCallback
 * @param {Function} onClose
 * @param {Function} onDestroy
 * @param {Boolean} centered
 * @param {Object} extraClassNames Additional class names for all parts.
 * See defaultClassNames.
 * @return {ModalInstance}
 */
export function Modal({
  content,
  onInit,
  onShow,
  onUpdate,
  onCallback,
  onClose,
  onDestroy,
  centered = false,
  extraClassNames = null,
}) {
  const classNames = Object.assign({}, defaultClassNames);
  extraClassNames &&
    Object.entries(extraClassNames).forEach(([key, values]) => {
      classNames[key] += " " + values;
    });

  const containerClassNames = [classNames.container, classNames.showing];
  centered && containerClassNames.push(classNames.centered);

  const container = Object.assign(document.createElement("div"), {
    className: containerClassNames.join(" "),
  });
  const inner = Object.assign(document.createElement("div"), {
    className: classNames.content,
    innerHTML: content,
  });

  let state = "open";
  let closeButton = null;
  let appliedControllers = [];

  /**
   * Handle the close click event
   * @param event
   */
  function handleCloseClick(event) {
    event.preventDefault();
    close();
  }

  /**
   * Close the modal
   */
  function close() {
    if (state !== "open") {
      return;
    }
    state = "closing";
    onClose && onClose(getInstance());
    container.classList.add(classNames.closing);
    setTimeout(() => {
      deInit();
      document.body.removeChild(container);
      state = "destroyed";
      stack--;
      if (stack === 0) {
        document.body.classList.remove(classNames.scrollLock);
      }
      onDestroy && onDestroy();
    }, animationSpeed);
  }

  function deInit() {
    Controllers.unapply(appliedControllers);
    closeButton.removeEventListener("click", handleCloseClick);
  }

  /**
   * Initialise the content of the modal
   */
  function init() {
    closeButton =
      inner.querySelector(
        "[data-modal-close], ." + classNames.closeButton.replace(" ", ".")
      ) ||
      Object.assign(document.createElement("button"), {
        className: classNames.closeButton,
        innerText: "close",
      });
    if (closeButton.dataset.modalClose) {
      closeButton.innerText = closeButton.dataset.modalClose;
    }
    inner.insertBefore(closeButton, inner.firstElementChild);
    closeButton.addEventListener("click", handleCloseClick);
    appliedControllers = Controllers.apply(inner);
  }

  /**
   * Update the content of the modal and initialize it again
   * @param {String} html
   */
  function update(html) {
    deInit();
    inner.innerHTML = html;
    init();
    onUpdate && onUpdate(getInstance());
  }

  /**
   * Returns the open state.
   * @return {boolean}
   */
  function isOpen() {
    return state === "open";
  }

  /**
   * Passes the instance and any arguments to a registered callback if one is provided.
   * @param args Any arguments
   */
  function callback(...args) {
    if (onCallback) {
      onCallback(getInstance(), ...args);
    }
  }

  /**
   * Get the public interface
   * @return {ModalInstance}
   */
  function getInstance() {
    return {
      close,
      update,
      callback,
      isOpen,
      container,
      content: inner,
    };
  }

  // insert:
  container.appendChild(inner);
  document.body.appendChild(container);
  stack++;

  // expose via the DOM:
  inner.dataset.modal = "1";
  inner.getModal = getInstance;

  // first initialisation
  init();
  if (stack === 1) {
    document.body.classList.add(classNames.scrollLock);
  }

  setTimeout(() => {
    container.classList.remove(classNames.showing);
    onShow && onShow();
  }, animationSpeed);

  const instance = getInstance();
  onInit && onInit(instance);
  return instance;
}

/**
 * Returns the containing modal instance for the given element.
 * @param {HTMLElement} element The element that's INSIDE the modal.
 * @return {ModalInstance|null}
 */
export function getContainingModal(element) {
  let parent = element;
  while (parent !== document.body && !parent.dataset.modal) {
    parent = parent.parentElement;
  }
  if (parent.dataset.modal && typeof parent.getModal === "function") {
    return parent.getModal();
  }
  return null;
}
