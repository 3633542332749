/**
 * @typedef {Object} FormSetOptions
 * @property {String} prefix
 * @property {"before"|"after"|"append"|"prepend"} mode="before"
 * @property {Element} template
 * @property {HTMLElement} target The element used as reference for inserting. Defaults to template.
 * @property {HTMLElement} form The parent element of management inputs. Defaults to target.
 */

/**
 * The available management form input postfixes.
 * @type {{total: string, min: string, initial: string, max: string}}
 */
const managementInputs = {
  total: "TOTAL_FORMS",
  initial: "INITIAL_FORMS",
  min: "MIN_NUM_FORMS",
  max: "MAX_NUM_FORMS",
};

/**
 * FormSet
 */
export default class FormSet {
  /**
   * @type {Element}
   */
  target = null;
  /**
   * @type {Element}
   */
  template = null;
  prefix = null;

  /**
   * Initialize the FormSet.
   * @param {FormSetOptions} options The options.
   */
  constructor({prefix, template, target, form, mode = "before"}) {
    this.prefix = prefix;
    this.mode = mode;
    this.template = template;
    this.target = target || template;

    if (!target && ["append", "prepend"].includes(mode)) {
      throw new Error(`Mode ${mode} not possible without a target!`);
    }

    const formElement = form || this.target;

    this.inputs = Object.entries(managementInputs).reduce((inputs, [key, postfix]) => {
      const selector = `input[name="${prefix}-${postfix}"]`;
      const element = formElement.querySelector(selector);
      if (!element) {
        throw new Error(
          `Unable to find management input ${selector} in ${formElement}`
        );
      }
      return Object.assign(inputs, {
        [key]: element,
      });
    }, {});
  }

  /**
   * Returns the current number of forms.
   * @return {number}
   */
  getTotal() {
    return parseInt(this.inputs.total.value, 10);
  }

  /**
   * Sets the current number of forms.
   * @param {number} total
   */
  setTotal(total) {
    this.inputs.total.value = total;
  }

  /**
   * Returns the maximum number of forms in this formset.
   * @return {number}
   */
  getMax() {
    return parseInt(this.inputs.max.value, 10);
  }

  /**
   * Creates a DocumentFragment with the given index.
   * @param {number} index
   * @return {DocumentFragment}
   */
  getTemplateFragment(index) {
    const html = this.template.innerHTML.replaceAll("__prefix__", `${index}`);
    return document.createRange().createContextualFragment(html);
  }

  /**
   * Returns the name of the field in this formset with the given index.
   * @param {string} name
   * @param {number} index
   * @return {string}
   */
  getFieldName(name, index) {
    return `${this.prefix}-${index}-${name}`;
  }

  /**
   * Adds a new form and optionally sets initial values of form fields.
   * @param {Object} [initial] Initial values for this form as an object with field
   * names and their values. Provide only the field name, not the formset prefix or
   * index.
   * @return {Element|Boolean}
   */
  addForm(initial) {
    if (!this.canAdd()) {
      return false;
    }
    const index = this.getTotal();
    const fragment = this.getTemplateFragment(index);

    if (initial) {
      Object.entries(initial).forEach(([name, value]) => {
        setFieldValue(fragment, this.getFieldName(name, index), value);
      });
    }

    let target = this.target;
    let ref = this.target;
    if (this.mode === "append") {
      target.appendChild(fragment);
    } else {
      if (this.mode === "prepend") {
        ref = ref.firstChild;
      } else {
        // before or after
        target = ref.parentElement;
        if (this.mode === "after") {
          ref = ref.nextSibling;
        }
      }
      target.insertBefore(fragment, ref);
    }
    this.setTotal(index + 1);
    switch (this.mode) {
      case "append":
        return target.lastElementChild;
      case "prepend":
        return target.firstElementChild;
      case "before":
        return ref.previousElementSibling;
      case "after":
        return ref.nextElementSibling;
    }
    return null;
  }

  canAdd() {
    return this.getTotal() < this.getMax();
  }

  destroy() {
    //
  }
}

/**
 * Sets the value of a field specified by the given name within the container.
 * @param {Document,DocumentFragment,Element} container Container to find the fields in.
 * @param {String} name The name of the field.
 * @param {*} value The value the field should have.
 */
function setFieldValue(container, name, value) {
  const fields = container.querySelectorAll(`[name="${name}"]`);
  for (const field of fields) {
    if (field.tagName === "SELECT") {
      [...field.options].forEach(
        (option) => (option.selected = option.value === value)
      );
    } else {
      if (["radio", "checkbox"].includes(field.type)) {
        field.selected = field.value === value;
        continue;
      }
      field.value = value;
    }
  }
}
