import Splide from "@splidejs/splide";
import GLightbox from "glightbox";
import {fetchURL} from "../utils/ajax";
import {Modal} from "../modal";

import {getScrollTop} from "../utils/scrolling";
import {Controllers} from "../utils/controllers";
import {createElement} from "../utils/elements";

/**
 * A container with lightbox links in it
 * @param {HTMLElement} list
 * @return {function(): void}
 */
function LightboxList(list) {
  const links = [...list.querySelectorAll("a")];
  const lightbox = GLightbox({
    elements: links.map((a) => ({
      href: a.href,
    })),
    autoplayVideos: true,
  });

  links.forEach((link, i) =>
    link.addEventListener("click", (event) => {
      event.preventDefault();
      lightbox.openAt(i);
    })
  );
}

Controllers.register("lightbox-list", LightboxList);

/**
 * Header video controller
 * @param {HTMLElement}container
 * @return {function(): void}
 */
function HeaderVideo(container) {
  const video = container.querySelector("video");
  if (!video) {
    return;
  }

  const {
    containerClass,
    toggleClass,
    toggledClass,
    toggleText,
    toggledText,
  } = container.dataset;

  const toggleContainer = Object.assign(document.createElement("div"), {
    className: containerClass,
  });
  const toggle = Object.assign(document.createElement("button"), {
    className: toggleClass,
    innerText: toggleText,
  });

  function handleClick(event) {
    event.preventDefault();
    video.muted = !video.muted;
    if (video.muted) {
      toggle.classList.remove(toggledClass);
      toggle.innerText = toggleText;
    } else {
      toggle.classList.add(toggledClass);
      toggle.innerText = toggledText;
    }
  }

  toggle.addEventListener("click", handleClick);
  toggleContainer.appendChild(toggle);
  container.appendChild(toggleContainer);

  return () => {
    toggle.removeEventListener("click", handleClick);
    container.removeChild(toggle);
  };
}

Controllers.register("header-video", HeaderVideo);

/**
 * Obfuscated element handler
 * @param {HTMLElement} element
 */
function ObfuscatedElement(element) {
  const {type, value} = element.dataset;
  const types = {
    email: {
      tagName: "a",
      setProps: (e, readable) =>
        Object.assign(e, {
          href: `mailto:${readable}`,
          innerHTML: readable,
        }),
    },
  };
  const tokens = {dot: ".", at: "@"};
  const tokenExp = new RegExp(`\\|(${Object.keys(tokens).join("|")})\\|`, "g");

  function toReadable(obfuscated) {
    return window.atob(obfuscated).replace(tokenExp, (match, token) => tokens[token]);
  }

  const button = Object.assign(document.createElement("button"), {
    className: element.className,
    innerHTML: element.innerHTML,
  });

  function deObfuscate() {
    const {tagName, setProps} = types[type] || {
      tagName: "span",
      setProps: (e, innerHTML) => Object.assign(e, {innerHTML}),
    };
    button.parentNode.replaceChild(
      setProps(document.createElement(tagName || "span"), toReadable(value)),
      button
    );
    element.firstChild.focus();
  }

  button.addEventListener("click", deObfuscate, {
    once: true,
    passive: true,
  });

  element.innerHTML = "";
  for (const key of ["type", "value"]) {
    element.removeAttribute(`data-${key}`);
  }
  element.appendChild(button);
  element.setAttribute("aria-live", "true");
  element.setAttribute("aria-relevant", "text");
  return () => {
    button.removeEventListener("click", deObfuscate);
    element.innerHTML = button.innerHTML;
  };
}

Controllers.register("obfuscate", ObfuscatedElement);

/**
 * Allows animating the closing of a details element by delaying the toggle and first
 * adding a data-closing attribute for the animation time and then removing the "open"
 * attribute like normal details elements do.
 * @param {HTMLDetailsElement} details The <details> element.
 */
function DetailsElement(details) {
  const summary = details.querySelector("summary");
  const closing = "data-closing";
  function toggle(event) {
    if (details.open) {
      event.preventDefault();
      details.setAttribute(closing, "");
      setTimeout(() => {
        details.removeAttribute("open");
        details.removeAttribute(closing);
      }, details.dataset.speed || 500);
    }
  }
  summary.addEventListener("click", toggle);
  return () => summary.removeEventListener("click", toggle);
}

Controllers.register("details", DetailsElement);

function MenuElement(details) {
  const summary = details.querySelector("summary");

  function onClickOutside(event) {
    if (details.open && event.clickedInsideMenu !== details) {
      summary.click();
    }
  }

  function onClick(event) {
    event.clickedInsideMenu = details;
  }

  details.addEventListener("click", onClick);
  document.body.addEventListener("click", onClickOutside);
  return () => {
    details.removeEventListener("click", onClick);
    document.body.removeEventListener("blur", onClickOutside);
  };
}

Controllers.register("menu", MenuElement);

/**
 * Expandable element controller
 * @param {HTMLElement} container
 */
function ExpandableElement(container) {
  let state = true;
  const {collapsedText, expandedText, toggleClass, collapsedClass} = container.dataset;
  const toggle = Object.assign(document.createElement("button"), {
    className: toggleClass,
  });

  function getText() {
    return state ? collapsedText : expandedText;
  }

  function handleToggle(event) {
    event.preventDefault();
    setState(!state);
  }

  function setState(newState, speed = 500) {
    state = newState;
    toggle.innerText = getText();
    const indicator = state ? "data-collapsing" : "data-expanding";

    function applyState() {
      if (speed) {
        container.removeAttribute(indicator);
      }
      if (state) {
        container.classList.add(collapsedClass);
      } else {
        container.classList.remove(collapsedClass);
      }
    }

    if (speed) {
      container.setAttribute(indicator, "");
      if (state) {
        // we are collapsing, ensure we scroll to the top of our container
        const startPosition = getScrollTop();
        const endPosition = container.getBoundingClientRect().top;
        if (endPosition < 0) {
          window.scrollTo({
            top: startPosition + endPosition - 50,
            left: 0,
            behavior: "smooth",
          });
        }
      }
      setTimeout(applyState, speed);
    } else {
      applyState();
    }
  }

  setState(state, 0);
  toggle.addEventListener("click", handleToggle);
  container.parentNode.insertBefore(toggle, container.nextElementSibling);
}

Controllers.register("expandable", ExpandableElement);

export function Paginator(container) {
  const links = [...container.querySelectorAll("a")];

  function handleClick(event) {
    event.preventDefault();
    const link = event.target;
    const {loading} = link.dataset;
    if (loading) {
      return;
    }
    link.setAttribute("data-loading", 1);

    fetchURL(link.href)
      .then(
        (response) => response.text(),
        (error) => console.error(error)
      )
      .then((html) => {
        const temp = Object.assign(document.createElement("div"), {
          innerHTML: html,
        });
        Controllers.apply(temp);
        let child = temp.firstElementChild;
        while (child) {
          container.parentNode.appendChild(child);
          child = temp.firstElementChild;
        }
        cleanUp();
        container.parentNode.removeChild(container);
      });
  }

  function cleanUp() {
    links.forEach((a) => a.removeEventListener("click", handleClick));
  }

  links.forEach((a) => a.addEventListener("click", handleClick));
  return () => cleanUp();
}

Controllers.register("paginator", Paginator);

/**
 * Carousel
 * @param {HTMLElement} container
 */
export function Carousel(container) {
  const slider = new Splide(container, {
    autoWidth: true,
    pagination: false,
    trimSpace: false,
  }).mount();

  const {prev, next} = slider.Components.Arrows.arrows;

  /**
   * Hides "previous" when at beginning or "next" when at the end.
   * @param {Number} index Slide index
   * @param {Boolean} visible Whether the slide is visible
   */
  function updateArrows(index, visible) {
    if (index === 0) {
      prev.disabled = visible;
    }
    if (index + 1 === slider.length) {
      next.disabled = visible;
    }
  }

  slider.on("visible", (slide) => updateArrows(slide.index, true));
  slider.on("hidden", (slide) => updateArrows(slide.index, false));

  return () => slider.destroy(true);
}

Controllers.register("carousel", Carousel);

/**
 * Creates a modal from a link.
 * @param {HTMLAnchorElement} a
 * @param {Object} [modalParams]
 */
export function ModalLink(a, modalParams) {
  const baseUrl = window.location.href;
  const baseTitle = window.title;
  const baseState = window.history.state;

  const url = a.href;
  const {modalClass, pushState = false, delay = 0} = a.dataset;
  const bodyClass = a.dataset.bodyClass ? a.dataset.bodyClass.split(" ") : [];

  // extract the params we use ourselves:
  const {onInit, onClose, onDestroy, ...restParams} = modalParams || {};
  modalClass && Object.assign(restParams, {extraClassNames: {container: modalClass}});

  let modal = null;
  let popped = false;
  let open = false;

  function handlePopState({state}) {
    if (!state || !state.modalURL) {
      if (modal && modal.isOpen()) {
        popped = true;
        modal.close();
      }
      return;
    }

    if (modal && state.modalURL) {
      if (state.modalURL === url) {
        // our url, we should open
        if (!modal.isOpen()) {
          openModal();
        }
      } else {
        // not our url, we should be closed:
        if (modal.isOpen()) {
          popped = true;
          modal.close();
        }
      }
    }
  }

  function openModal() {
    if (open) {
      return;
    }
    open = true;
    fetchURL(url)
      .then(
        (response) => response.text(),
        (error) => console.error(error)
      )
      .then((content) => {
        modal = Modal({
          content,
          /**
           * Modal is opened, update the URL to reflect that from the clicked link.
           */
          onInit: (instance) => {
            if (pushState) {
              history.pushState(
                {
                  modalURL: url,
                },
                a.dataset.title || a.title || window.title,
                url
              );
              window.addEventListener("popstate", handlePopState);
            }
            bodyClass.forEach((cls) => document.body.classList.add(cls));
            onInit && onInit(instance);
          },
          onClose: (instance) => {
            if (pushState && !popped) {
              history.pushState(baseState, baseTitle, baseUrl);
            }
            popped = false;
            open = false;
            bodyClass.forEach((cls) => document.body.classList.remove(cls));
            onClose && onClose(instance);
          },
          /**
           * Modal is closed, we're back on the page the modal was opened from.
           */
          onDestroy: () => {
            if (pushState) {
              window.removeEventListener("popstate", handlePopState);
            }
            onDestroy && onDestroy();
          },
          ...restParams,
        });
      });
  }

  function handleClick(event) {
    event.preventDefault();
    setTimeout(() => openModal(), delay);
  }

  a.addEventListener("click", handleClick);
  return () => {
    a.removeEventListener("click", handleClick);
    modal && modal.close();
  };
}

Controllers.register("modal-url", ModalLink);

/**
 * Adds a close button to messages.
 * @param {HTMLLIElement} element
 * @return {cleanup}
 */
export function Message(element) {
  const button = document.createElement("button");
  button.innerText = element.dataset.closeText || "close";
  button.classList.add(element.dataset.buttonClass);
  button.addEventListener("click", handleClick);
  element.appendChild(button);

  function handleClick(event) {
    event.preventDefault();
    element.classList.add(element.dataset.closeClass || "hidden");
    cleanup();
  }

  function cleanup() {
    button.removeEventListener("click", handleClick);
  }

  return cleanup;
}

Controllers.register("message", Message);

/**
 * Shows/updates a preview of an image
 * @param {HTMLInputElement} input
 * @return {undefined|function(): *}
 */
export function ImagePreview(input) {
  if (!window.FileReader) {
    return;
  }

  input.addEventListener("change", handleChange, {passive: true});
  let img = input.parentElement.querySelector("img");
  const previewContainer =
    input.parentElement.querySelector("label") || input.parentElement;

  function handleChange() {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (!img) {
          img = document.createElement("img");
          previewContainer.appendChild(img);
          // input.parentElement.insertBefore(img, input);
          previewContainer.classList.remove(previewContainer.dataset.blankClass);
        }
        img.setAttribute("src", event.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  return () => input.removeEventListener("change", handleChange);
}

Controllers.register("image-preview", ImagePreview);

/**
 * Initializes a video with a pretty play button.
 * @param {HTMLElement} container
 */
function VideoController(container) {
  const {playText, playClass} = container.dataset;

  /**
   * @type {HTMLVideoElement}
   */
  const video = container.querySelector("video");
  if (!video) {
    console.warn("No <video> element found in ", container);
  }

  function handleClick(event) {
    event.preventDefault();
    video.controls = true;
    video.play().then(remove);
  }

  function remove() {
    button.removeEventListener("click", handleClick);
    button.parentElement.removeChild(button);
  }

  const button = createElement(
    "button",
    {
      class: playClass || "button",
    },
    [playText || "play"]
  );
  container.appendChild(button);
  video.controls = false;
  button.addEventListener("click", handleClick);
  return remove;
}

Controllers.register("video", VideoController);
