/**
 * Creates an element with the given attributes and children.
 * @param {String} tagName Tag name.
 * @param {Object} [attributes] Object with attributes.
 * @param {Array} [children] Array of child elements.
 * @return {HTMLElement}
 */
export function createElement(tagName, attributes, children) {
  const element = document.createElement(tagName);
  attributes &&
    Object.entries(attributes).forEach(([attr, value]) =>
      element.setAttribute(attr, value)
    );

  function addChild(child) {
    element.appendChild(
      typeof child === "string" ? document.createTextNode(child) : child
    );
  }

  if (children) {
    Array.isArray(children) ? children.forEach(addChild) : addChild(children);
  }
  return element;
}
