import autocomplete from "autocompleter";
import {getContainingModal} from "../modal";
import {fetchURL} from "../utils/ajax";
import {Controllers} from "../utils/controllers";

/**
 * Global handler of the location selection bar
 * @type {{controller: (function(*): *), fetch: LocationSelection.fetch, update: update}}
 */
const LocationSelection = (() => {
  let container = null;

  function update(html) {
    if (!html.trim()) {
      if (container) {
        container.parentNode.removeChild(container);
        container = null;
      }
      return;
    }
    if (!container) {
      document.body.insertAdjacentHTML("beforeend", html);
      container = document.body.lastElementChild;
    } else {
      // convert html to a node:
      const temp = Object.assign(document.createElement("div"), {innerHTML: html})
        .firstElementChild;
      // replace the container with the node:
      container.parentNode.replaceChild(temp, container);
      container = temp;
    }

    const {showClass} = container.dataset;
    if (showClass) {
      container.classList.add(showClass);
      const clearShowClass = () => container.classList.remove(showClass);
      window.addEventListener("scroll", clearShowClass, {
        once: true,
      });
    }

    Controllers.apply(container);
  }

  return {
    controller: (element) => (container = element),
    fetch: () => {
      fetchURL("/locations/selection/")
        .then(
          (response) => response.text(),
          (error) => console.error(error)
        )
        .then(update);
    },
    update,
  };
})();

Controllers.register("location-selection", LocationSelection.controller);

/**
 * Select button controller of a location.
 * @param {HTMLFormElement} form
 * @return {function(): *}
 */
function LocationSelectButton(form) {
  const button = form.querySelector("button");

  function handleSubmit(event) {
    event.preventDefault();
    fetchURL(form.action, {
      method: "POST",
      body: new FormData(form),
    })
      .then(
        (response) => response.json(),
        (error) => console.error(error)
      )
      .then((result) => {
        if (result.selected) {
          button.classList.add(button.dataset.selectedClass);
        } else {
          button.classList.remove(button.dataset.selectedClass);
        }
        button.innerText = result.label;
        LocationSelection.update(result.barHTML || "");
        const modal = getContainingModal(form);
        if (modal) {
          setTimeout(() => modal.close(), 300);
        }
      });
  }

  form.addEventListener("submit", handleSubmit);
  return () => form.removeEventListener("submit", handleSubmit);
}

Controllers.register("location-select", LocationSelectButton);

/**
 * Location filter controller
 * @param {HTMLFormElement} form
 * @return {function(): void}
 * @constructor
 */
function LocationFilter(form) {
  const select = form.querySelector("select");
  const choices = [...select.options].map(({value, innerText}) => ({
    value,
    label: innerText,
  }));
  const input = Object.assign(document.createElement("input"), {
    type: "search",
    required: true,
    className: form.className + "__input",
    autocomplete: "off",
  });
  select.parentElement.insertBefore(input, select);

  const values = [
    ...(select.selectedOptions || select.querySelectorAll("[selected]")),
  ].map((o) => o.value);

  const categoryTagOptions = form.querySelectorAll("#category_tags option");
  const categoryTags = [...categoryTagOptions].map((option) => ({
    label: option.innerText,
    value: option.value,
  }));

  const autocompleter = autocomplete({
    input,
    preventSubmit: true,
    fetch: (text, update) => {
      if (text === "") {
        update(categoryTags);
        return;
      }
      const search = text.toLowerCase();
      update(
        choices
          .filter(
            (choice) =>
              !values.includes(choice.value) &&
              choice.label.toLowerCase().indexOf(search) >= 0
          )
          .slice(0, 10)
      );
    },
    showOnFocus: true,
    onSelect: ({value}) => {
      values.push(value);
      for (const option of select.options) {
        if (option.value === value) {
          option.setAttribute("selected", "selected");
        }
      }
      form.submit();
    },
  });
  return () => autocompleter.destroy();
}

Controllers.register("location-filter", LocationFilter);

/**
 * Location download modal handler
 * @param {HTMLFormElement} form
 * @return {function(): void}
 * @constructor
 */
function LocationDownload(form) {
  const inputs = [...form.querySelectorAll("input[type=checkbox]")];

  function update(event) {
    fetchURL(form.action, {
      method: form.method,
      body: new FormData(form),
    })
      .then(
        (response) => response.text(),
        (error) => console.error(error)
      )
      .then((html) => {
        let parent = form.parentNode;
        while (parent) {
          if (parent.dataset.modal) {
            parent.getModal().update(html);
            LocationSelection.fetch();
            break;
          }
          parent = parent.parentNode;
        }
      });
  }

  inputs.forEach((input) => input.addEventListener("change", update));

  return () => inputs.forEach((input) => input.removeEventListener("change", update));
}

Controllers.register("location-download", LocationDownload);

function OpenSelectionBar(trigger) {
  const {toggleClass, delay} = trigger.dataset;
  let parent = trigger.parentNode;
  while (parent) {
    if (parent.dataset.do === "location-selection") {
      break;
    }
    parent = parent.parentNode;
  }
  function handleClick(event) {
    parent.classList.add(toggleClass);
    setTimeout(() => parent.classList.remove(toggleClass), delay * 3);
  }
  trigger.addEventListener("click", handleClick);
  return () => trigger.removeEventListener("click", handleClick);
}

Controllers.register("location-trigger", OpenSelectionBar);
