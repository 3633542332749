// import SwipeListener from "swipe-listener";
import throttle from "lodash.throttle";
import {fetchURL} from "./utils/ajax";

import {Controllers} from "./controllers";

const Header = ((header, menu) => {
  const defaultFocus = document.getElementById("top");
  const scrollLock = "page--scroll-locked";
  const {classList} = header;
  const {menuClass} = header.dataset;

  let open = false;

  function handleEscape(event) {
    if (event.keyCode !== 27) {
      return;
    }

    event.preventDefault();
    setState(false);
    defaultFocus.focus();
  }

  function handleBlur() {
    setState(false);
    defaultFocus.focus();
  }

  function setState(state) {
    console.trace("setState", state);
    open = state;
    open ? classList.add(menuClass) : classList.remove(menuClass);
    open
      ? document.body.classList.add(scrollLock)
      : document.body.classList.remove(scrollLock);
    if (open) {
      document.addEventListener("keydown", handleEscape);
      document.activeElement.addEventListener("blur", handleBlur, {once: true});
    } else {
      document.removeEventListener("keydown", handleEscape);
    }
  }

  function toggleMenu() {
    setState(!open);
  }

  return {
    toggleMenu,
    closeMenu: () => setState(false),
    openMenu: () => setState(true),
  };
})(document.getElementById("header"), document.getElementById("menu-list"));

function StickyObjects(stickyClassName = "sticky") {
  const elements = [];
  const [top] = ["top"];

  function update() {
    elements.forEach((element) => {
      const {
        sticky = top,
        stickyClass = stickyClassName,
        stickyMedia,
      } = element.dataset;

      if (stickyMedia && window.matchMedia) {
        if (!window.matchMedia(stickyMedia).matches) {
          return;
        }
      }

      const rect = element.getBoundingClientRect();
      if (sticky === top) {
        element.classList.toggle(stickyClass, rect.y < 0);
      }
    });
  }

  const updateHandler = throttle(update, 300);

  window.addEventListener("scroll", updateHandler, {passive: true});

  return {
    register: (element) => {
      elements.push(element);
    },
  };
}

/**
 * The DOMContentLoaded event on document triggers when the DOM is ready and blocking scripts are executed.
 */
document.addEventListener("DOMContentLoaded", () => {
  document.documentElement.classList.replace("no-js", "js");
  // applyControllers(globalControllers, document.body);
  Controllers.apply(document.body);

  const html = document.documentElement;
  let offset = html.scrollTop;

  function setScrolled(from) {
    const scrollTop = html.scrollTop;
    const diff = from - scrollTop;
    if (Math.abs(diff) > 30) {
      offset = scrollTop;
      html.setAttribute("data-scrolled", diff > 0 ? "up" : "down");
    }
  }

  const scrolledHandler = throttle(setScrolled, 100);

  function handleExternalLinks(event) {
    const rel = event.target.getAttribute("rel");
    if (rel && rel.indexOf("external") >= 0) {
      event.preventDefault();
      window.open(event.target.href);
    }
  }

  window.addEventListener("scroll", () => scrolledHandler(offset), {passive: true});
  html.addEventListener("click", handleExternalLinks);
  setScrolled(0);
});
window.addEventListener("load", () => {
  // remove the data-intro attribute to stop triggering animations on window resize:
  const {intro} = document.body.dataset;
  if (intro) {
    setTimeout(() => {
      document.body.removeAttribute("data-intro");
    }, parseInt(intro));
  }
  const stickies = StickyObjects();
  [...document.querySelectorAll("[data-sticky]")].forEach(stickies.register);
});
// if (module.hot) {
//   console.info("module.hot!!");
//   module.hot.accept("./main.js", () => console.info("Accepting main.js"));
// }
