/**
 * window.requestAnimationFrame polyfill
 * @type {Function}
 */
export const raf =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.setTimeout;

/**
 * Linear easing function. Used as default for utility functions. Install `js-easing-functions` to get the nice ones.
 * @param {Number} elapsed Elapsed time in microseconds.
 * @param {Number} initialValue The value to ease from.
 * @param {Number} amountOfChange The value to ease to.
 * @param {Number} duration The time the easing should take in microseconds.
 * @return {Number} The value for the given arguments.
 */
export function easeLinear(elapsed, initialValue, amountOfChange, duration) {
  return amountOfChange * (elapsed / duration) + initialValue;
}

/**
 * Animate callback. Function called by animate().
 * @callback animateCallback
 * @param {Number} value The value for the current tick or frame.
 * @param {Number} elapsed The number of milliseconds that have elapsed.
 */

/**
 * Smoothly animates between the given values with the given duration calling callback with the current value.
 * @param {animateCallback} callback The function which is called with the current value and elapsed time.
 * @param {Number} from The initial value.
 * @param {Number} to The final value.
 * @param {Number} duration in milliseconds, how long it should take.
 * @param {Function} [easingFunction=easeLinear] The function to use to ease the scroll. Install `js-easing-functions`
 * to get some easing functions that match this signature.
 * @return {Promise<any>}
 */
export function animate(callback, from, to, duration, easingFunction = easeLinear) {
  return new Promise(resolve => {
    const start = Date.now();
    const tick = () => {
      const elapsed = Date.now() - start;
      const value = easingFunction(elapsed, from, to, duration);

      callback(value, elapsed);

      if (elapsed < duration) {
        raf(tick);
      } else {
        resolve();
      }
    };
    tick();
  });
}

/**
 * Utility method to generate a CSS `@keyframes` at-rule. This is useful to generate keyframes for animations which are
 * impossible to create using the CSS `cubic-bezier()` function such as `easeOutElastic`.
 * @param {Function} frame Callback accepting the current value and returning the contents of the keyframe.
 * @param {Number} from The value to animate from.
 * @param {Number} to The value to animate to.
 * @param {Function} [easingFunction = easeLinear] The easing function to use. Get more from `js-easing-functions`.
 * @param {Number} [steps = 10] The number of steps in the keyframes.
 * @param {String} [name = 'my-animation'] The name of the keyframes.
 * @return {String} The @keyframes at-rule as a string
 * @example
 * generateKeyFrames(value => `transform: scale(${value});`, 0, 1, easeOutElastic, 10, 'elastic-entrance');
 * // outputs:
 * `@keyframes elastic-entrance {
 *     0% { transform: scale(0); }
 *     10% { transform: scale(1.25); }
 *     20% { transform: scale(1.125); }
 *     30% { transform: scale(0.875); }
 *     40% { transform: scale(1.0313); }
 *     50% { transform: scale(1.0156); }
 *     60% { transform: scale(0.9844); }
 *     70% { transform: scale(1.0039); }
 *     80% { transform: scale(1.002); }
 *     90% { transform: scale(0.998); }
 *     100% { transform: scale(1); }
 * }`
 * // then apply it to an element, for example:
 * .foo {
 *     animation: elastic-entrance 1s backwards;
 * }
 */
export function generateKeyFrames(
  frame,
  from,
  to,
  easingFunction = easeLinear,
  steps = 10,
  name = "my-animation"
) {
  let keyFrames = `@keyframes ${name} {\n`;
  const step = 100 / steps;

  for (let i = 0; i <= steps; i++) {
    const percentage = i * step;
    keyFrames += `    ${percentage}% { ${frame(
      Number(easingFunction(percentage, from, to, 100).toFixed(4))
    )} }\n`;
  }

  return keyFrames + "}";
}
