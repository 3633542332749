import {animate, easeLinear} from "./animation";

/**
 * Returns the current vertical scroll offset of the window.
 * @return {Number}
 */
export const getScrollTop = () =>
  window.pageYOffset || document.documentElement.scrollTop;

/**
 * Smoothly scrolls the window to the given element
 * @param {Element} element The element to scroll to.
 * @param {Number} duration in milliseconds, how long it should take.
 * @param {Function} [easingFunction=easeLinear] The function to use to ease the scroll. Install `js-easing-functions`
 * to get some easing functions that match this signature.
 * @return {Promise<any>}
 * @example
 * import {easeInOutExpo} from 'js-easing-functions';
 * import {scrollToElement} from 'utils';
 * const element = document.getElementById('target');
 * // scrolls to #target in 1 second using `easeInOutExpo` as easing function.
 * scrollToElement(element, 1000, easeInOutExpo);
 */
export function scrollToElement(element, duration, easingFunction = easeLinear) {
  const startPosition = getScrollTop();
  const endPosition = element.getBoundingClientRect().top;

  console.info("Animate from", startPosition, "to", startPosition + endPosition);
  return animate(
    y => {
      window.scrollTo(0, y);
    },
    startPosition,
    startPosition + endPosition,
    duration,
    easingFunction
  );
}
