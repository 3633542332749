import autocomplete from "autocompleter";
import {fetchURL} from "../utils/ajax";
import {Controllers} from "../utils/controllers";
import {createElement} from "../utils/elements";
import {getContainingModal, Modal} from "../modal";
import {ModalLink} from "./content";
import FormSet from "../utils/formset";

export const NoResult = Symbol("NoResult");

/**
 * Checks whether the given result Array is considered a 'no result'.
 * @param {Array} results
 * @return {boolean}
 */
function isNoResult(results) {
  return results.length === 0 || (results.length === 1 && results[0] === NoResult);
}

/**
 * Event handler that prevents all.
 * @param {Event} event
 */
function preventAll(event) {
  event.preventDefault();
  event.stopImmediatePropagation();
}

/**
 * Submits a form when an input changes.
 * @param {HTMLInputElement} input
 * @return {function(): *}
 */
function ChangeSubmit(input) {
  function handleChange() {
    input.form.submit();
  }
  input.addEventListener("change", handleChange);
  return () => input.removeEventListener("change", handleChange);
}

Controllers.register("change-submit", ChangeSubmit);

/**
 * Autocomplete controller
 * @param {HTMLInputElement} input
 * @param {{}} options
 * @return {function(): void}
 */
function AutoComplete(input, options = {}) {
  const {url, resize, param = "search"} = input.dataset;

  const {onResults, emptyMsg, noResultsItem, customize} = options;

  input.setAttribute("autocomplete", "off");

  function parseData(data) {
    if ("results" in data && "count" in data) {
      return data.results;
    }
    return data;
  }

  function checkEmpty(data) {
    if (data.length === 0) {
      return [NoResult];
    }
    return data;
  }

  const instance = autocomplete({
    input,
    emptyMsg,
    minLength: 2,
    debounceWaitMs: 500,
    preventSubmit: true,
    fetch: (text, update) => {
      fetchURL(url, {queryParams: {[param]: text}})
        .then((response) => response.json())
        .then(parseData)
        .then(checkEmpty)
        .then((results) => (onResults ? onResults(results) : results))
        .then(update);
    },
    render: (item, currentValue) => {
      if (item === NoResult) {
        if (typeof noResultsItem === "function") {
          return noResultsItem();
        }
        const element = createElement("div", {}, emptyMsg || "No results");
        element.addEventListener("click", preventAll);
        return element;
      }
      return createElement("div", {}, item.label || "");
    },
    customize(input, inputRect, container, maxHeight) {
      if (typeof customize === "function") {
        customize(input, inputRect, container, maxHeight);
      } else if (resize === "parent") {
        // set the width to the width of the inputs parent element:
        let width = input.parentElement.getBoundingClientRect().width;
        // check if we have a form field which is marked as a 'left' field.
        // if so, we should expand our dropdown until we found the 'right' field
        if (input.parentElement.classList.contains("form__field--left")) {
          // now add up the widths of all next elements until we found the 'right' class
          let next = input.parentElement.nextElementSibling;
          do {
            width += next.getBoundingClientRect().width;
            next = next.classList.contains("form__field--right")
              ? null
              : next.nextElementSibling;
          } while (next);
        }
        container.style.width = width + "px";
      }
    },
    ...options,
  });

  return () => instance.destroy();
}

Controllers.register("autocomplete", AutoComplete);

/**
 * Autocomplete for a hidden field
 * @param {HTMLInputElement} input The *hidden* input.
 */
function HiddenAutoComplete(input) {
  const searchInput = createElement("input", {
    type: "text",
    id: input.id + "-text",
    value: input.dataset.display,
    placeholder: input.placeholder,
  });
  Object.entries(input.dataset).forEach(([key, value]) => {
    if (key !== "do") {
      searchInput.setAttribute("data-" + key, value);
    }
  });
  input.parentElement.insertBefore(searchInput, input);

  // clear the target when the search input changes to a blank value
  searchInput.addEventListener("change", (event) => {
    if (event.value === "") {
      input.value = "";
    }
  });

  return AutoComplete(searchInput, {
    onSelect: function onSelect(item) {
      input.value = item.value || "";
      searchInput.value = item.label || "";
    },
  });
}

Controllers.register("hidden-autocomplete", HiddenAutoComplete);

function TemplateAutoComplete(input) {
  return AutoComplete(input, {
    onSelect: function onSelect(item) {
      console.info({item});
    },
  });
}

Controllers.register("template-autocomplete", TemplateAutoComplete);

function AddEditAutocomplete(container) {
  const {
    id,
    url,
    addUrl,
    addUrlParam,
    param,
    addMessage,
    placeholder,
    buttonSelector = "[data-button]",
    labelAdd = "Add",
    labelEdit = "Edit",
  } = container.dataset;

  const input = createElement("input", {
    id,
    placeholder: placeholder || "",
    type: "text",
    "data-url": url,
    "data-param": param,
  });
  const button = document.querySelector(buttonSelector);

  // selected value:
  let noResults = true;

  /**
   * @type {null|{edit_url: string}}
   */
  let selected = null;

  function handleCreate() {
    window.location.href =
      addUrl +
      (addUrlParam ? `?${addUrlParam}=${encodeURIComponent(input.value)}` : "");
  }

  /**
   * Handles the user pressing "Enter" without results.
   * @param {KeyboardEvent} event
   */
  function handleKeyUp(event) {
    if (event.key === "Enter") {
      if (noResults) {
        return handleCreate();
      }
      handleButtonClick(event);
    }
  }

  /**
   * Edit the selected item
   * @param event
   * @return {void|*}
   */
  function handleButtonClick(event) {
    if (!selected) {
      return handleCreate();
    }
    if (selected.edit_url) {
      return (window.location.href = selected.edit_url);
    }
  }

  input.addEventListener("keyup", handleKeyUp);
  container.appendChild(input);

  button.addEventListener("click", handleButtonClick);

  const destroy = AutoComplete(input, {
    emptyMsg: addMessage,
    noResultsItem: () => {
      const item = createElement("div", {}, addMessage);
      item.addEventListener("click", preventAll);
      return item;
    },
    // custom callback after results are received but before rendering:
    onResults: function onResults(results) {
      noResults = isNoResult(results);
      selected = noResults ? null : results[0];
      button.innerText = noResults ? labelAdd : labelEdit;
      button.disabled = false;
      // clear selected:
      return results;
    },
    onSelect: function onSelect(item) {
      input.value = item.label;
      selected = item;
    },
  });

  return () => {
    destroy();
    input.removeEventListener("keyup", handleKeyUp);
    button.removeEventListener("click", handleButtonClick);
  };
}

Controllers.register("autocomplete-add-edit", AddEditAutocomplete);

/**
 * Formset controller
 * @param {HTMLElement} container
 * @return {function(): void}
 */
function FormSetController(container) {
  const {
    formsetListTemplate,
    formsetTemplate,
    formsetName,
    formsetTarget,
    formsetTargetPrefix,
    formsetTargetField,
    formsetTargetPosition = "beforeend",
  } = container.dataset;
  const templateElement = document.getElementById(formsetTemplate);
  if (!templateElement) {
    console.warn(`Couldn't find the template element #${formsetTemplate}`);
  }
  const inputs = [...container.querySelectorAll("input")];
  const fields = inputs.map((input) => input.dataset.target || input.name);
  const defaultState = {};
  const initialFormsInput = document.getElementById(
    "id_" + formsetName + "-TOTAL_FORMS"
  );
  const initialForms = parseInt(String(initialFormsInput.value));
  const prefixToken = "__prefix__";

  // state:
  let formCount = initialForms;
  let state = defaultState;

  function isValid() {
    return fields.every((field) => !!state[field]);
  }

  function reset() {
    state = {};
    addButton.disabled = true;
    inputs.forEach((e) => (e.value = e.defaultValue));
    inputs[0].focus();
  }

  function getFieldName(index, name) {
    console.info(formsetName + "-" + index + "-" + name);
    return formsetName + "-" + index + "-" + name;
  }

  function getFieldId(index, name) {
    return "id_" + getFieldName(index, name);
  }

  function handleAdd(event) {
    event.preventDefault();
    // add a form to the fieldset:
    // copy the template HTML
    let html = templateElement.innerHTML;
    Object.entries(state).forEach(([field, data]) => {
      // replace all __<field>__ with label:
      html = html.replace("__" + field + "__", data.label);
      Object.entries(data).forEach(([key, value]) => {
        if (!["label", "value"].includes(key)) {
          html = html.replaceAll("__" + key + "__", value);
        }
      });
    });

    // we create a temporary fragment to update the nodes in:
    const fragment = document.createRange().createContextualFragment(html);

    // for each field, update the value:
    for (const field of fields) {
      const value = state[field];
      const templateFieldId = getFieldId(prefixToken, field);
      const input = fragment.querySelector("#" + templateFieldId);
      if (!input) {
        console.warn("#" + templateFieldId + " not found in", html);
        continue;
      }
      const fieldName = getFieldName(formCount, field);
      input.setAttribute("name", fieldName);
      input.setAttribute("id", getFieldId(formCount, field));
      input.setAttribute("value", value.value);
      // input.setAttribute("data-autocomplete-search", value.label);
      input.dataset.display = value.label;
    }

    // now convert it back to HTML:
    const div = document.createElement("div");
    div.appendChild(fragment.cloneNode(true));
    html = div.innerHTML.replaceAll(prefixToken, "" + formCount);

    // the target will contain the new field:
    let target;

    if (formsetTarget) {
      // formsetTarget = id of element to append to
      target = document.getElementById(formsetTarget);
      if (!target) {
        console.warn(`No formset target element found with ID ${formsetTarget}`);
        return;
      }
      target.insertAdjacentHTML(formsetTargetPosition, html);
    } else if (formsetTargetPrefix && formsetTargetField) {
      // we have a target (id) prefix and a field to get the remainder from
      const targetField = state[formsetTargetField];
      if (!targetField) {
        console.warn(`No state variable found named '${targetField}'`);
        return;
      }
      const targetId = formsetTargetPrefix + targetField.value;
      target = document.getElementById(targetId);
      if (!target) {
        // console.warn(`No target element found with ID ${targetId}`);
        const listTemplate = document.getElementById(formsetListTemplate);
        let listHTML = listTemplate.innerHTML;
        listHTML = listHTML.replaceAll(
          /__([a-z_]+)\.([a-z_]+)__/g,
          (match, field, key) => state[field][key]
        );
        listTemplate.insertAdjacentHTML("beforebegin", listHTML);
        target = listTemplate.previousElementSibling;
      }
      target.insertAdjacentHTML(formsetTargetPosition, html);
    } else {
      // just insert before the template. We ignore formsetTargetPosition now.
      templateElement.insertAdjacentHTML("beforebegin", html);
      target = templateElement.previousElementSibling;
    }

    // update the form count:
    formCount++;
    initialFormsInput.value = formCount;
    // clear the state:
    reset();

    // Apply any controllers to the new element:
    Controllers.apply(templateElement.previousElementSibling);
  }

  const addButtonSelector = "[data-formset-add]";
  const addButton = container.querySelector(addButtonSelector);
  if (addButton) {
    addButton.addEventListener("click", handleAdd);
  } else {
    console.warn(`Couldn't find an add button with selector ${addButtonSelector}`);
  }

  const callbacks = [];
  callbacks.concat(
    [...container.querySelectorAll("[data-url]")].map((input) => {
      const {emptyMessage, emptyButton, emptyButtonUrl} = input.dataset;

      let noResults = false;

      /**
       * Called when an item is selected in the autocompleter
       * @param {{value: String, label: String}} item
       */
      function selectItem(item) {
        state[input.dataset.target || input.name] = item;
        input.value = item.label;

        if (isValid()) {
          addButton.disabled = false;
        }
      }

      return AutoComplete(input, {
        emptyMsg: emptyMessage,
        // custom callback after results are received but before rendering:
        onResults: (results) => {
          noResults = isNoResult(results);
          return results;
        },
        noResultsItem: () => {
          const content = [emptyMessage || "No results"];
          if (emptyButton && emptyButtonUrl) {
            const button = createElement(
              "a",
              {
                href: emptyButtonUrl,
                target: "_blank",
                class: "button button--primary",
              },
              [emptyButton]
            );
            // button.addEventListener("click", (event) => {
            //   event.preventDefault();
            // });
            button.dataset.centered = "1";
            callbacks.push(
              (() => {
                let form, modal;
                function handleSubmit(event) {
                  event.preventDefault();
                  fetchURL(form.action, {method: form.method, body: new FormData(form)})
                    .then((response) => {
                      const itemJSON = response.headers.get("X-Object-Data");
                      if (itemJSON) {
                        selectItem(JSON.parse(itemJSON));
                        modal.close();
                        return Promise.resolve("");
                      }
                      return response.text();
                    })
                    .then((html) => {
                      html && modal.update(html);
                    });
                }
                return ModalLink(button, {
                  centered: true,
                  onInit: (instance) => {
                    modal = instance;
                    form = modal.content.querySelector("form");
                    if (form) {
                      form.addEventListener("submit", handleSubmit);
                    }
                  },
                  onDestroy: () => {
                    form && form.removeEventListener("submit", handleSubmit);
                  },
                });
              })()
            );
            content.push(button);
          }
          const item = createElement(
            "div",
            {
              class: "empty-result",
            },
            content
          );
          item.addEventListener("click", (ev) => ev.stopImmediatePropagation());
          return item;
        },
        onSelect: selectItem,
      });
    })
  );

  return () => {
    callbacks.forEach((cb) => cb());
    addButton.removeEventListener("click", handleAdd);
  };
}

Controllers.register("formset", FormSetController);

/**
 * Initializes formset behavior
 * @param {HTMLElement} element
 * @return {function()|void}
 */
function FormSetClassController(element) {
  const {
    formsetPrefix,
    formsetTemplate,
    formsetInitial,
    formsetTrigger,
  } = element.dataset;

  const templateSelector = formsetTemplate || `#${formsetPrefix}-template`;
  const templateElement = document.querySelector(templateSelector);
  if (!templateElement) {
    return console.warn("No template found with selector " + templateSelector);
  }
  const form = element.form || element.closest("form");
  if (!form) {
    return console.warn("No <form> element found for element:", element);
  }
  const triggers =
    formsetTrigger === "self"
      ? [element]
      : formsetTrigger
      ? [...document.querySelectorAll(formsetTrigger)]
      : [...element.querySelectorAll(`[data-formset-add="${formsetPrefix}"]`)];

  if (!triggers) {
    return console.warn("No trigger elements found!");
  }

  const formSet = new FormSet({
    form: form,
    prefix: formsetPrefix,
    template: templateElement,
  });

  const cleanups = triggers.map((trigger) => {
    const initial =
      formsetInitial || trigger.dataset.formsetInitial
        ? JSON.parse(trigger.dataset.formsetInitial)
        : null;

    function handleClick(event) {
      event.preventDefault();
      const element = formSet.addForm(initial);
      // console.info({element});
      Controllers.apply(element);
    }

    trigger.addEventListener("click", handleClick);
    return () => trigger.removeEventListener("click", handleClick);
  });

  return () => {
    cleanups.forEach((cb) => cb());
    formSet.destroy();
  };
}

Controllers.register("formset2", FormSetClassController);

/**
 * Observes the input specified by the elements data-input="#selector" attribute for
 * changes and updates the contents with the file name.
 * @param {HTMLElement} element
 * @return {function(): void}
 */
function FileNameDisplayController(element) {
  const {input} = element.dataset;
  const inputElement = document.querySelector(input);

  function handleChange(event) {
    const path = event.target.value;
    let fileName = path.substring(
      path.indexOf("\\") >= 0 ? path.lastIndexOf("\\") : path.lastIndexOf("/")
    );
    if (fileName.indexOf("\\") === 0 || fileName.indexOf("/") === 0) {
      fileName = fileName.substring(1);
    }
    element.innerText = fileName;
  }
  inputElement.addEventListener("change", handleChange);
  return () => inputElement.removeEventListener("change", handleChange);
}

Controllers.register("display-filename", FileNameDisplayController);

/**
 * Observes the input for changes and disables visible inputs in it's parent element.
 * @param {HTMLInputElement} input The (checkbox) input. Can have a
 * data-disable-selector attribute which specifies which elements to disable in it's
 * parentElement.
 */
function DisableFormRow(input) {
  const row = input.parentElement;
  const {disableSelector = "input[type='text'], select, textarea"} = input.dataset;
  function handleChange() {
    const elements = [...row.querySelectorAll(disableSelector)];
    elements.forEach((e) => (e.disabled = input.checked));
  }
  input.addEventListener("change", handleChange);
  return () => input.removeEventListener("change", handleChange);
}

Controllers.register("disable-form-row", DisableFormRow);

/**
 * Enables the button when the form is valid.
 * @param {HTMLInputElement|HTMLButtonElement} input
 */
function EnableWhenValid(input) {
  if (input.form && input.form.checkValidity) {
    function checkFormValid(form) {
      input.disabled = !form.checkValidity();
    }
    function handleEvent(event) {
      const {form} = event.target;
      if (form && form === input.form) {
        checkFormValid(form);
      }
    }
    checkFormValid(input.form);
    document.addEventListener("change", handleEvent);
    return () => document.removeEventListener("change", handleEvent);
  }
}

Controllers.register("enable-when-valid", EnableWhenValid);

/**
 * Modal form handler
 * Todo: how to handle redirects?
 * @param {HTMLFormElement} form
 * @return {function(): *}
 */
function ModalForm(form) {
  const modal = getContainingModal(form);
  if (!modal) {
    return console.warn("No modal found as parent of", form);
  }

  function handleSubmit(event) {
    event.preventDefault();

    fetchURL(form.action, {
      method: form.method,
      body: new FormData(form),
    }).then(
      (response) => {
        const objectData = response.headers.get("X-Object-Data");
        if (objectData) {
          modal.callback(JSON.parse(objectData));
        }
        return response.text().then(modal.update);
      },
      (response) => {
        console.warn("Submit failed", response);
      }
    );
  }

  form.addEventListener("submit", handleSubmit);
  return () => form.removeEventListener("submit", handleSubmit);
}

Controllers.register("modal-form", ModalForm);

/**
 * Shows a modal to edit a profile list. Not initialized on load!
 * @param trigger
 */
function ProfileListEditor(trigger) {
  const {targetId, formUrl} = trigger.dataset;

  const targetInput = document.getElementById(targetId);
  if (!targetInput) {
    return console.warn(`Couldn't find target input #${targetId}`);
  }

  let url = formUrl;
  let modal;

  function edit() {
    fetchURL(url)
      .then((response) => response.text())
      .then((html) => {
        modal = new Modal({
          content: html,
          centered: true,
          extraClassNames: {content: "modal__content--form"},
          onCallback: (instance, data) => {
            trigger.innerText = data["title"];
            targetInput.value = data["id"];
            instance.close();
          },
        });
      });
  }

  function handleClick(event) {
    event.preventDefault();
    edit();
  }

  trigger.addEventListener("click", handleClick);
  if (!targetInput.value) {
    edit();
  }

  return () => {
    modal && modal.isOpen() && modal.close();
    trigger.removeEventListener("click", handleClick);
  };
}

Controllers.register("profile-list-editor", ProfileListEditor);

/**
 * Used to automatically trigger a click() on inputs when they are rendered.
 * @param {HTMLInputElement} input
 * @return {function(): void}
 */
function AutoTriggerInputController(input) {
  if (!input.value) {
    const timer = setTimeout(() => input.click());
    return () => clearTimeout(timer);
  }
}

Controllers.register("auto-trigger-input", AutoTriggerInputController);
