import {Controllers} from "../utils/controllers";
import {fetchURL} from "../utils/ajax";

function MenuToggle(toggle) {
  const target = document.querySelector(toggle.getAttribute("href"));
  const input = target
    ? target.querySelector("input:not([type='hidden']), textarea, select")
    : null;
  toggle.addEventListener("mousedown", (event) => {
    event.preventDefault();
    target.focus();
    input && input.focus();
  });
  toggle.addEventListener("click", (event) => {
    event.preventDefault();
  });
}

Controllers.register("toggle-menu", MenuToggle);

function SearchForm(form) {
  const results = document.getElementById("search-results");
  const input = document.getElementById("search-query");

  function search() {
    const url = new URL(form.action, window.location.href, false);
    for (const entry of new FormData(form).entries()) {
      url.searchParams.append(...entry);
    }

    fetchURL(url, {
      method: form.method,
    })
      // body:
      .then(
        (response) => response.text(),
        (error) => {
          console.error(error);
        }
      )
      .then((text) => (results.innerHTML = text));
  }

  function handleSubmit(event) {
    event.preventDefault();
    search();
  }
  form.addEventListener("submit", handleSubmit);

  /**
   * Delays visiting a link until the overlay is closed or fills in a query from the
   * dataset and executes a search
   * @param event
   */
  function handleLinkClick(event) {
    if (event.target.tagName.toLowerCase() === "a") {
      if (event.target.dataset.query) {
        // a query, search for it
        input.value = event.target.dataset.query;
        input.focus();
        return search();
      }

      // any other link closes the dialog and is visited:
      document.getElementById("top").focus();
      event.preventDefault();
      setTimeout(() => {
        window.location.href = event.target.href;
      }, 300);
    }
  }

  results.addEventListener("click", handleLinkClick);

  return () => {
    form.removeEventListener("submit", handleSubmit);
    results.removeEventListener("click", handleLinkClick);
  };
}

Controllers.register("search-form", SearchForm);
